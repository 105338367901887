body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.game-board {
  border: 1px solid #000;
}

.board-row {
  display: flex;
}

.square {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.game-info {
  margin-left: 20px;
}

.dahnUA div:first-child {
  overflow: visible !important;
  display: flex;
  align-items: center;
}

button {
  font-size: 12px !important;
}

.garrKE div:first-child {
  overflow: initial !important;
  white-space: pre-line !important
}

.text_anitem textarea:not([rows]) {
  min-height: 6em !important;
  max-height: 1em !important;
}

.add_btn button {
  padding: 8px 20px;
  border-radius: 20px;
  border: none;
}

.add_btn button:first-child {
  margin: 0 10px 0 0;
}


.quest-img {
  max-width: 500px;
  width: 100%;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
}
.quest-img img {
  width: 100%;
  height: 280px;
}
.progress-bar {
  background-color: #EE3124 !important;
  border-radius: 5px;
}
.counter {
  width: 100%;
  /* max-width: 15%; */
}
.option-1 {
  display: flex;}
  .option-1 .option{width: 100%;
    flex-basis: 100%;
    max-width: 300px;}

    .bottom button {
      background-color: #EE3124;
      padding: 6px 15px;
      color: #fff;
      border-radius: 40px;
      margin: 0 10px 0 0;
      border: none;
  }
  .bottom {
    text-align: center;
}

.count-ques p {
  margin: 0;
}
.progress-main {
  width: 100%;
  max-width: 85%;
}
.count-ques-main {
  display: flex;
  align-items: center;
} 

.count-ques {
  width: 100%;
  max-width: 15%;
}

.ques-no {
  padding: 7px;
  width: 100%;
  max-width: 20%;
}
.kAfkVG div:first-child {
  overflow: visible !important;
}